@import url('https://fonts.googleapis.com/css?family=Lato');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: 1.5rem;
  margin: 0 0 2rem 0;
}

h1 {
  font-size: 2.4rem;
  font-weight: 700;
  margin: 0 0 3rem 0;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: .5rem 0;
  color: #909090;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}


@media (max-width: 767px) {
  p {
    font-size: .8rem;
    margin: 0 0 1rem 0;
  }

  h1 {
    font-size: 1.2rem;
    margin: .6rem 0 1rem;
  }

  h2 {
    font-size: .9rem;
    margin: 0;
  }

  h3 {
    font-size: .9rem;
    margin-bottom: .2rem;
  }
}